var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" Register Form ")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"email","error":failed ? 'The Email field is required': null,"hasSuccess":passed,"name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'The Password field is required': null,"hasSuccess":passed,"name":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'The Confirm field is required': null,"hasSuccess":passed,"name":"confirm"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)}),_c('fg-input',[_c('checkbox',{attrs:{"name":"subscribe"},model:{value:(_vm.subscribe),callback:function ($$v) {_vm.subscribe=$$v},expression:"subscribe"}},[_vm._v("Subscribe to newsletter ")])],1)],1),_c('div',{staticClass:"card-footer text-right"},[_c('button',{staticClass:"btn btn-fill btn-info btn-wd",attrs:{"type":"submit"}},[_vm._v("Register")])])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }